<template>
  <main id="app">
    <div class="content">
      <div v-if="showCurr === 'index' || null" class="card-pair-parent">
        <div class="card-pair-l">
          <h1>Testaa vaatekaappisi vastuullisuus</h1>
          <button class="btn btn-start btn-center" @click="start()">
            ALOITA
          </button>
        </div>
        <div
          class="card-pair-r"
          :style="{
            backgroundImage: 'url(' + require('@/assets/img/1.png') + ')',
          }"
        >
          <!--<div class="img-holder">-->
          <!--<img src="@/assets/img/1.png" alt="img-1" />-->
        </div>
      </div>
      <div class="question-parent" v-if="showCurr === 'question'">
        <section
          class="question-card"
          v-for="(question, index) in questions"
          v-bind:key="question.id"
          :class="index === currQ ? 'question-active' : ''"
        >
          <template v-if="index === currQ">
            <!-- actual question -->
            <transition
              appear
              appear-class="fade-enter"
              appear-to-class="fade-enter-to"
              (2.1.8+)
              appear-active-class="fade-enter-active"
              appear-leave-to="fade-leave-to"
            >
              <div class="card-pair-parent">
                <div class="card-pair-l">
                  <article v-show="questionStage === 1" class="question-holder">
                    <p>{{ currentPair }}</p>

                    <h2>{{ question.header }}</h2>
                    <form class="form">
                      <div
                        class="radio-button-style"
                        v-for="(answer, idx) in question.answers"
                        :key="`${question.id}-${idx}`"
                      >
                        <label
                          class="radiobutton-label"
                          :for="`${question.id}-${idx}`"
                          >{{ answer.txt }}
                          <input
                            class="visuallyhidden"
                            type="radio"
                            :name="question.header"
                            :value="idx"
                            v-model="responses[currQ]"
                            :id="`${question.id}-${idx}`"
                          />
                          <span class="radio"></span>
                        </label>
                      </div>
                    </form>
                    <p v-if="error === true">
                      Vastaathan kysymykseen jatkaaksesi
                    </p>
                  </article>
                  <!-- after description-->
                  <article v-show="questionStage === 2" class="content-holder">
                    <div class="inner">
                      <p>{{ currentPair }}</p>
                      <p class="selite" v-html="question.after"></p>
                    </div>
                  </article>
                  <div class="button-holder">
                    <button class="btn btn-next" @click="nextQuestion">
                      JATKA
                    </button>
                  </div>
                </div>
                <div
                  class="card-pair-r"
                  :style="'background-image: url(' + question.img + ')'"
                ></div>
              </div>
            </transition>
          </template>
        </section>
      </div>
      <div v-if="showCurr === 'results'" class="question-parent">
        <section class="results">
          <div class="btn-return-to-first-dia" @click="toBeginning()">
            <p>Tee vastuullisuustesti uudelleen</p>
          </div>
          <div class="options">
            <h2 v-if="result <= 4">
              Vastaustesi perusteella et painota vastuullisuutta, mutta sinusta
              löytyy paljon potentiaalia vastuullisempien valintojen tekijäksi.
              Aloita pienistä teoista, kuten nykyisen vaatevalikoimasi
              monipuolisemmasta hyödyntämisestä ja heräteostosten
              vähentämisestä. Voit myös päättää tehdä osan ostoksistasi
              brändeiltä, jotka käyttävät tuotteissaan kierrätysmateriaaleja.
            </h2>
            <h2 v-if="result >= 5 && result <= 9">
              Kuulostaa siltä, että teet paljon hyviä valintoja, vaikka
              vaatekaappisi ei olekaan vielä täysin vastuullinen. Jos haluat
              tehdä kestävämpiä päätöksiä, perehdy uuden vaatteen alkuperään
              ennen ostoa, pidennä vaatteidesi käyttöikää hoitamalla niitä hyvin
              ja tee ostoksesi suosimalla vastuullisia brändejä, joilla on
              mahdollisuus käyttää tuotteissaan myös kierrätysmateriaaleja.
            </h2>
            <h2 v-if="result >= 10">
              Onneksi olkoon, olet kestävien vaatevalintojen pioneeri. Suosi
              jatkossakin tuotteita, joilla on vastuullisuudesta kertova
              sertifikaatti, kuten Fair Trade, GOTS tai GRS. Tutustuta myös
              lähipiirisi parempien päätösten maailmaan – mitä useampi meistä
              valitsee vastuullisemmin, sitä helpommin kestäviä vaatteita ja
              tuotteita on saatavilla.
            </h2>
            <p>
              Tutustu uuteen 100% kierrätysmateriaaleista valmistettuun Globe
              Hope –vaatemallistoon!
            </p>
            <a href="https://globehope.fi/collections/vaatteet" target="_blank"
              ><button class="btn btn-next">Tutustu mallistoon</button></a
            >
          </div>
          <Share />
        </section>
      </div>
    </div>
  </main>
</template>
<script>
import Share from "./components/Share.vue";
// images
import img1 from "./assets/img/1.png";
import img2 from "./assets/img/2.png";
import img3 from "./assets/img/3.png";
import img4 from "./assets/img/4.png";
import img5 from "./assets/img/5.png";
import img6 from "./assets/img/6.png";
import img7 from "./assets/img/7.png";
import img8 from "./assets/img/8.png";
// import img9 from "./assets/img/9.png";
// import img10 from "./assets/img/10.png";
// import img11 from "./assets/img/11.png";

export default {
  name: "App",
  components: {
    Share
  },
  data() {
    return {
      showCurr: "index",
      currQ: 0,
      questionStage: 1,
      currPair: 1,
      selectedValue: null,
      error: false,
      result: 0,
      responses: [],
      questions: [
        {
          id: "q-1",
          header: "Tiedätkö vaatteidesi alkuperän?",
          answers: [
            {
              txt:
                "Tsekkaan valmistajan taustan aina ennen ostoa. En osta tuotetta, jonka alkuperä jää epäselväksi.",
              value: 2,
            },
            {
              txt:
                "Tiedän, mistä vaatteet kannattaa ostaa ollakseni varma, että vaate on tuotettu vastuullisesti.",
              value: 1,
            },
            {
              txt:
                "En kiinnitä vaatteen alkuperään juurikaan huomiota. Ostan vaatteita, jotka sopivat minulle ja näyttävät hyvältä.​",
              value: 0,
            },
          ],
          after:
            "Tuotantoketjun läpinäkyvyys on yksi vastuullisen toimijan tunnusmerkeistä. Jos haluat tehdä vastuullisempia vaatehankintoja, etsi kansainvälisesti hyväksytyillä sertifikaatilla merkittyjä tuotteita, kuten esim. Fair Trade, GOTS tai GRS. Sertifikaatit edellyttävät jäljitettävyyttä, rajoittavat kemikaalien käyttöä tuotteissa ja antavat raamit ympäristö- ja sosiaaliselle vastuulle.",
          img: img1,
        },
        {
          id: "q-2",
          header: "Mitä huomioit vaatteiden materiaalivalinnoissa?",
          answers: [
            {
              txt:
                "Vaatekaappini on iloinen sekoitus mitä milloinkin. En muista, että vaatteissani olisi käytetty juurikaan erikoisviljeltyjä tai uudelleenkäytettyjä materiaaleja.",
              value: 0,
            },
            {
              txt:
                "Suosin luomua: esimerkiksi luomupuuvilla, luomupellava ja luomuvilla ovat vaatekaappini vakiomateriaaleja.",
              value: 1,
            },
            {
              txt:
                "Panostan kierrätys- ja ylijäämämateriaaleista tehtyihin vaatteisiin.",
              value: 2,
            },
          ],
          after:
            "Materiaalien ympäristövaikutukset eivät ole yksiselitteisiä. Esimerkiksi luomu ei aina takaa ekologisuutta vedenkulutuksensa takia. Suosi kierrätettyjä materiaaleja, sillä niiden hiilijalanjälki on mahdollisimman pieni.",
          img: img2,
        },
        {
          id: "q-3",
          header:
            "Oletko löytänyt kotoasi vaatteen, jonka olemassaolon olit unohtanut?​",
          answers: [
            {
              txt: "Teen yhtenään yllättäviä löytöjä omasta vaatekaapistani​.",
              value: 0,
            },
            { txt: "Joskus.", value: 1 },
            {
              txt: "Olen hyvin perillä siitä, mitä vaatteita omistan.",
              value: 2,
            },
          ],
          after:
            "Unohdetun vaatteen löytäminen omasta kaapista voi olla iloinen yllätys. Jos huomaat, että löytöjä tapahtuu usein ja/tai unohdettuja vaatteita on paljon, tee vaatekaapin mini-inventaario aina ennen ostoksille lähtöä ja osta vain tarpeeseen.",
          img: img3,
        },
        {
          id: "q-4",
          header: "Huomioitko vastuullisuutta vaateostoksilla?​",
          answers: [
            {
              txt:
                "Vastuullisuus on plussaa, mutta en tee ostopäätöstä sen perusteella.",
              value: 1,
            },
            {
              txt:
                "Valitsen aina vaatteita ja materiaaleja, joiden ympäristökuormitus on mahdollisimman pieni ja tuotanto on vastuullista.",
              value: 2,
            },
            {
              txt:
                "En kiinnitä juurikaan huomiota näihin asioihin vaateostoksilla.",
              value: 0,
            },
          ],
          after:
            "Vastuullisuus on valinta, jonka jokainen voi tehdä. Mitä useampi ihminen valitsee kestävämpiä vaihtoehtoja, sitä helpommin ja monipuolisemmin vastuullisia vaatteita ja tuotteita on jatkossa saatavilla.",
          img: img4,
        },
        {
          id: "q-5",
          header:
            "Ostatko hintavia vaatteita sen takia, että ne vaikuttavat laadukkailta?",
          answers: [
            {
              txt:
                "Mielestäni hinta voi kertoa laadusta, mutta en tee ostopäätöstäni pelkästään hinnan perusteella.",
              value: 2,
            },
            { txt: "Uskon, että hinnakas tuote on myös laadukas.", value: 1 },
            {
              txt:
                "Mitä halvempi, sen parempi. En halua käyttää rahaa vaatteisiin.",
              value: 0,
            },
          ],
          after:
            "Hinta ei kerro suoraan laadusta. Vaatteen laatu perustuu koko tuotantoketjuun aina langoista viimeisteltyihin tuotteisiin. Laadukas tuote kestää käyttöä pidempään. On kestävämpi valinta ostaa laadukasta harvemmin kuin halpaa usein.",
          img: img5,
        },
        {
          id: "q-6",
          header: "Millaista vaatehuoltoa teet?​",
          answers: [
            {
              txt: "Pesen vaatteen jokaisen käyttökerran jälkeen.",
              value: 0,
            },
            {
              txt:
                "En panosta vaatehuoltoon, pesen vaatteen, kun se on likainen.",
              value: 1,
            },
            {
              txt:
                "Pesen mahdollisimman harvoin ja tuuletan vaatteen käytön jälkeen pidentääkseni vaatteen elinikää​.",
              value: 2,
            },
          ],
          after:
            "Vaatteen elinikää voi pidentää monin tavoin, esimerkiksi: <ul><li>noudattamalla annettua tuotteen hoito-ohjetta.</li><li>jatkuvan pesemisen sijaan tuotteen tuulettamisella tai pakastamisella.</li><li>vaatteen tai asusteen korjaamisella.</li><li>neuleen tai villakangastakin puhdistaminen harjaamalla.</li></ul>",
          img: img6,
        },
        {
          id: "q-7",
          header: "Miten teet rikkinäiselle vaatteelle?",
          answers: [
            { txt: "Heitän sen roskikseen.", value: 0 },
            {
              txt:
                "Käytän vaatteen materiaalin uudelleen (esim. siivousrättinä) tai vien vaatteen tekstiilijätekeräykseen.",
              value: 1,
            },
            {
              txt:
                "Jos tarvitsee, ompelen irronneen napin, vaihdan vetoketjun, tai lyhennän hihat​.",
              value: 2,
            },
          ],
          after:
            "Pyri pidentämään vaatteesi käyttöikää korjaamalla ja tuunamalla sitä. Tuotteen voi korjauttaa ammattilaisella myös edullisesti.",
          img: img7,
        },
        {
          id: "q-8",
          header: "Mitä teet, kun ehjä vaate ei ole enää käytössäsi?​",
          answers: [
            {
              txt: "Uusiokäytän, myyn sen kirpputorilla tai annan tutulle​.",
              value: 2,
            },
            { txt: "Vien sen tekstiilikeräykseen.", value: 1 },
            { txt: "Heitän sen roskikseen.", value: 0 },
          ],
          after:
            "Vaatteesta luopuminen ei ole ongelmatonta ympäristön kannalta. Halutessasi voit palauttaa tämän malliston tuotteen Globe Hope Oy:lle omalla kustannuksellasi. Kierrätämme sen maksimaalista resurssitehokkuutta noudattaen.",
          img: img8,
        },
      ],
    };
  },
  mounted() {
    /*
    if (this.$gtm.enabled()) {
      console.log('gtm ready')
    }
    */
  },
  computed: {
    currentPair() {
      return `${this.currPair}/8`;
    },
  },
  methods: {
    start() {
      this.currQ = 0;
      this.showCurr = "question";
    },
    toBeginning() {
      this.currQ = 0;
      this.questionStage = 1;
      this.showCurr = "index";
    },
    /**
     * @description
     * diojen vaihtologiikka:
     * dioja on kolme tasoa, jotka tsekataan muuttujalla { Number } questionStage
     * jos questionStage === 3, vaihdetaan kysymystä
     * kysymyspareja on 8. Jos kaikki setit on näytetty, palautetaan tulosdia.
     */
    nextQuestion() {
      this.error = false;
      if (this.currPair <= 8) {
        if (this.questionStage === 1) {
          if (this.responses.length < this.currQ + 1) {
            this.error = true;
            return;
          }
          this.questionStage++;
          return;
        }
        //if (this.questionStage === 2) {
        //  this.questionStage++;
        //  return;
        //}
        if (this.questionStage === 2) {
          this.currQ++;
          this.currPair++;
          this.questionStage = 1;
          if (this.currPair > 8) {
            this.calculateResult();
            this.currPair = 1;
            this.showCurr = "results";
          }
          return;
        }
      } else {
        this.calculateResult();
        this.showCurr = "results";
      }
    },
    /**
     * @description clean up array and calculate sum of array
     */
    calculateResult() {
      let results = this.responses.map((v, i) => {
        return this.questions[i].answers[v].value;
      });

      console.log(results);
      this.result = results.reduce((a, b) => a + b, 0);
      console.log("sum", this.result)

      this.$gtm.trackEvent({
        category: 'Globe Hope — Testaa vastuullisuutesi',
        action: 'click',
        label: this.result,
        value: this.result
      })
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: avenir;
  src: url("~@/assets/AvenirNext-Regular-08.ttf");
}
@import "./assets/style.scss";

.btn {
  font-family: avenir;
  font-weight: 600;
}

main {
  margin: 15px;
  @media (min-width: $m) {
    margin: 30px;
  }
}

.content {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $xl;
  min-height: calc(100vh - 30px);
  @media (min-width: $m) {
    min-height: calc(100vh - 60px);
  }
  & > div {
    flex-grow: 1;
  }
}

.question-parent {
  //background-color: #f5ebe9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $m) {
    height: 100%;
    flex-grow: 1;
  }
  .question-card {
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    //flex-grow: 1;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;

    &.question-active {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .question-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: auto;
      transition: all 0.3s ease-in-out;
      @media (max-width: $m) {
        font-size: 14px;
      }
      @media (min-width: $m) {
        width: calc(100% - 30px);
      }
    }
    .content-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      margin: auto;
      transition: all 0.3s ease-in-out;
      @media (max-width: $m) {
        flex-direction: column-reverse;
        font-size: 14px;
        //width: calc(100% - 15px);
      }
      @media (min-width: $m) {
        width: calc(100% - 30px);
      }
    }
  }
  .results {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: $dark-bg;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
  }
}

.button-holder {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  position: relative;
  //background: #fff;
  z-index: 20;
  width: 100%;
  //padding: 15px 0 0px;
}

.btn-return-to-first-dia {
  cursor: pointer;
}

.btn-return-to-first-dia > p {
  text-decoration: underline;
}

.bg-img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.bottom {
  padding: 10px;
  display: flex;
  justify-content: center;
}
</style>
